import React, { useEffect, useState } from 'react';
import Map, { Marker, Source, Layer, LayerProps } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tracking.css';
import { TrackingOrdersByStatus } from '../../Common/SharedApis/TrackingOrder'; // Import your tracking method
import { useNavigate } from 'react-router-dom';

const riderIcon = 'https://cdn-icons-png.flaticon.com/512/2006/2006883.png'; 
const destinationIcon = 'https://cdn-icons-png.flaticon.com/512/25/25231.png'; 
const orderIcon = 'https://cdn-icons-png.flaticon.com/512/10375/10375479.png'; 

const MapboxToken = 'pk.eyJ1IjoidGhlbWV0YXN1bSIsImEiOiJjbTFiczFtMmQwZHdnMmxzNmRsZ292d3hiIn0.XvXHgjczGqyH7DPS8aeN_A';

// Define the types for your order data
interface Order {
  orderId: string;
  customerName: string;
  status: string;
  orderDateTime: string;
  specialInstructions: string;
  orderPriority: string;
  timeSinceOrder: string;
  coords?: { // Make coords optional
    latitude: number;
    longitude: number;
  };
  items: Array<{
    itemName: string;
    quantity: number;
  }>;
}

// Update the type for rider data
interface RiderData {
  currentLocation: { latitude: number; longitude: number };
  destination?: { latitude: number; longitude: number }; // Make destination optional
}

const OrderTracking: React.FC = () => {
  const initialRiderData: RiderData = {
    currentLocation: { latitude: 31.5204, longitude: 74.3587 },
    destination: undefined, // Initialize as undefined
  };

  const [riderData, setRiderData] = useState<RiderData>(initialRiderData);
  const navigate = useNavigate();
  const [selectedOrderCoords, setSelectedOrderCoords] = useState(initialRiderData.currentLocation);
  const [mapViewState, setMapViewState] = useState({
    longitude: selectedOrderCoords.longitude,
    latitude: selectedOrderCoords.latitude,
    zoom: 12,
  });
  const [orders, setOrders] = useState<Order[]>([]); // Specify the type for orders
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  const lineData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [riderData.currentLocation.longitude, riderData.currentLocation.latitude],
            riderData.destination ? 
              [riderData.destination.longitude, riderData.destination.latitude] : 
              [riderData.currentLocation.longitude, riderData.currentLocation.latitude],
          ],
        },
      },
    ],
  };

  const lineLayer: LayerProps = {
    id: 'rider-route',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 6,
    },
  };

  const handleOrderClick = (order: Order) => {
    if (order.coords) {
      setSelectedOrderCoords(order.coords);
      setRiderData((prev) => ({
        ...prev,
        destination: order.coords, // No longer conflicts with type
      }));
    } else {
      console.warn(`Order ${order.orderId} has no coordinates.`);
    }
  };

  // Fetch orders on component mount
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const status = 1; // Set the desired status code
        setLoading(true); // Start loading
        const fetchedOrders = await TrackingOrdersByStatus(status);
        if (fetchedOrders) {
          setOrders(fetchedOrders); // Update the orders state with fetched data
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message); // Set error message if an error occurs
        } else {
          setError("An unexpected error occurred.");
        }
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    setMapViewState({
      longitude: selectedOrderCoords.longitude,
      latitude: selectedOrderCoords.latitude,
      zoom: 12,
    });
  }, [selectedOrderCoords]);
  const handleButtonClick = () => {
    navigate("/ordertrackingleaflet"); // Use navigate to go to the "/Items" route
  };
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div className="col-3 p-3 border-right">
        <h6 className="text-center">Track Real-Time Orders</h6>
        <div className="mb-3">
          <label className="form-label">Track By Order ID</label>
          <input type="text" className="form-control" placeholder="Order ID" />
        </div>
        <div className="mb-3">
          <label className="form-label">Track By Rider</label>
          <input type="text" className="form-control" placeholder="Rider" />
        </div>
        <button className="btn btn-primary w-100 mb-2">Search</button>
        <button  className="btn btn-primary w-100" onClick={handleButtonClick}>Track Order On LeafLet</button>

      </div>

      <div style={{ width: '50%', position: 'relative' }}>
        <Map
          initialViewState={mapViewState}
          style={{ width: '100%', height: '100%' }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={MapboxToken}
        >
          <Marker
            longitude={riderData.currentLocation.longitude}
            latitude={riderData.currentLocation.latitude}
            anchor="bottom"
          >
            <img src={riderIcon} alt="Rider Icon" style={{ width: '30px', height: '30px' }} />
          </Marker>

          {riderData.destination && (
            <Marker
              longitude={riderData.destination.longitude}
              latitude={riderData.destination.latitude}
              anchor="bottom"
            >
              <img src={destinationIcon} alt="Destination Icon" style={{ width: '30px', height: '30px' }} />
            </Marker>
          )}

          {orders.map((order) => (
            order.coords ? ( // Check if coords exist
              <Marker
                key={order.orderId}
                longitude={order.coords.longitude}
                latitude={order.coords.latitude}
                anchor="bottom"
                onClick={() => handleOrderClick(order)}
              >
                <img src={orderIcon} alt="Order Icon" style={{ width: '30px', height: '30px' }} />
              </Marker>
            ) : null
          ))}

          <Source id="rider-route-source" type="geojson" data={lineData}>
            <Layer {...lineLayer} />
          </Source>
        </Map>
      </div>

      <div className="orders-container col-3 p-3">
        <h6>On the Way Orders</h6>
        <div className="row">
          {loading ? (
            <div className="shimmer-wrapper">
              <div className="shimmer-card"></div>
              <div className="shimmer-card"></div>
              <div className="shimmer-card"></div>
            </div>
          ) : error ? (
            <div className="text-danger">{error}</div>
          ) : (
            orders.map((order) => (
              <div key={order.orderId} className="col-12">
                <div className="card card-custom" onClick={() => handleOrderClick(order)}>
                  <div className="card-body">
                    <h5 className="card-title card-title-custom">Order #{order.orderId}</h5>
                    <p className="card-text card-text-custom">{order.customerName} - {order.status}</p>
                    <p className="card-text card-price-custom"><strong>Priority: {order.orderPriority}</strong></p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;
