import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogContent, Button } from "@mui/material";
import { getmyallresturent } from "../../Common/SharedApis/MyAllResutrant";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./Allresturents.css"; // Import your CSS file

const Allresturents: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId || localStorage.getItem("userId");
  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [error, setError] = useState<string | null>(null); // State for error messages

  useEffect(() => {
    if (!userId) {
      console.error("No userId found.");
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      try {
        const restaurantData = await getmyallresturent(userId);
        console.log("Fetched restaurant data:", restaurantData);
        setRestaurants(restaurantData.restaurants || []);
      } catch (error) {
        console.error("Error fetching restaurant data:", error);
        setError("Failed to fetch your registered restaurants. Please try again."); // Set error state
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, navigate]);

  const handleMoveToDashboard = (restaurant: any) => {
    const dataToSave = {
      city_id: restaurant.city_id,
      lat: restaurant.lat,
      lot: restaurant.lot,
      registredUserID: restaurant.registredUserID,
      restaurantId: restaurant.restaurantId,
      store_Name: restaurant.store_Name,
      store_area: restaurant.store_area,
      store_type: restaurant.store_type,
    };

    console.log("Selected restaurant data:", dataToSave);
    localStorage.setItem("restaurantData", JSON.stringify(dataToSave));
    navigate("/dashboard");
  };

  const handleModalClose = (event: object, reason: string) => {
    // Prevent closing the modal if clicked on backdrop or pressed the escape key
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    setIsModalOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  return (
    <div className={`restaurant-container ${isModalOpen ? "blur-background" : ""}`}>
      <Dialog open={isModalOpen} onClose={handleModalClose} fullWidth maxWidth="lg">
        <DialogContent>
          <h2>Your Registered Restaurants</h2>
          {restaurants.length > 0 ? (
            <div className="card-grid">
              {restaurants.map((restaurant) => (
                <div 
                  key={restaurant.restaurantId} // Changed to restaurantId
                  className="restaurant-card" 
                  style={{ cursor: "pointer" }} 
                >
                  <h3>{restaurant.store_Name}</h3>
                  <p>Address: {restaurant.store_area || "N/A"}</p>
                  <Button 
                    className="move-button" 
                    onClick={() => handleMoveToDashboard(restaurant)} 
                  >
                    Move To Dashboard
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <p>No restaurants found</p>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Allresturents;