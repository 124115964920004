import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  MdDashboard,
  MdOutlineTrackChanges,
  MdOutlineLogout,
  MdOutlineSettings,
  MdOutlineList,
  MdOutlineRestaurant,
  MdOutlineFilterNone,
  MdOutlineReport,
  MdOutlineKitchen,
  MdOutlineFoodBank,
  MdOutlineMap,
  MdOutlinePersonOutline
} from "react-icons/md";

export const baseConfig = {
  projectLink: "/",
  docsRepositoryBase: "",
  titleSuffix: "",
  search: true,
  header: true,
  headerText: "",
  footer: true,
  footerText: (
    <>
      <span>
        © SumBite {new Date().getFullYear()}
      </span>
    </>
  ),
  logo: (
    <>
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="logo"
        width="30"
        height="22"
      />
    </>
  ),
};

// Navigation sidebar configuration
export const appNavs = [
  {
    eventKey: "dashboard",
    icon: <MdDashboard />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    eventKey: "POS",
    icon: <MdOutlineFilterNone />,
    title: "POS",
    to: "/mainPOS",
  },
  {
    eventKey: "Items",
    icon: <MdOutlineList />,
    title: "Items",
    to: "/Item",
    children: [
      {
        eventKey: "Items",
        title: " Add New Item",
        to: "/Menue",
      },
      {
        eventKey: "Items",
        title: "All Items",
        to: "/Items",
      },
      {
        eventKey: "Category",
        title: "New Category",
        to: "/categorygrid",
      },
      {
        eventKey: "Category",
        title: "All Categories",
        to: "/All categorygrid",
      },
    ],
  },
  {
    eventKey: "Orders",
    icon: <MdOutlineFoodBank />,
    title: "Orders",
    to: "/Orderlist",
  },
  {
    eventKey: "allCustomers",
    icon: <MdOutlinePersonOutline />,
    title: "All Customers",
    to: "/allCustomers",
  },
  {
    eventKey: "Track Order",
    icon: <MdOutlineTrackChanges />,
    title: "Track Order",
    to: "/ordertrackingmapbox",
  },
  {
    eventKey: "Reports",
    icon: <MdOutlineReport />,
    title: "Reports",
    to: "/mainDashboard",
  },
  {
    eventKey: "Branches",
    icon: <MdOutlineRestaurant />,
    title: "Branches",
    to: "/Branches",
    children: [
      {
        eventKey: "Branches",
        title: "New Branche",
        to: "/New Branches",
      },
      {
        eventKey: "Branches",
        title: "All Branches",
        to: "/Allresturents",
      },
    ],
  },
  {
    eventKey: "KitchenDisplay",
    icon: <MdOutlineKitchen />,
    title: "Kitchen Display",
    to: "/kitchenDisplay",
  },
  {
    eventKey: "DeliveryAreaMap",
    icon: <MdOutlineMap />,
    title: "Delivery Area Map",
    to: "/DeliveryAreaMap",
  },
  {
    eventKey: "Settings",
    icon: <MdOutlineSettings />,
    title: "Settings",
    to: "/Settings",
  },
  {
    eventKey: "Logout",
    icon: <MdOutlineLogout />,
    title: "Logout",
    to: "/Logout",
  },
];

// Sidebar Component that renders the navigation
export const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  // Function to toggle submenu visibility
  const toggleSubMenu = (eventKey: string) => {
    setActiveMenu(activeMenu === eventKey ? null : eventKey);
  };

  return (
    <div className="sidebar">
      {appNavs.map((navItem, index) => (
        <div key={index} className="menu-item">
          <div
            className="nav-link"
            onClick={() => toggleSubMenu(navItem.eventKey)}
          >
            <span className="icon">{navItem.icon}</span>
            {navItem.title}
          </div>
          {navItem.children && activeMenu === navItem.eventKey && (
            <div className="submenu">
              {navItem.children.map((subNavItem, subIndex) => (
                <div key={subIndex} className="sub-menu-item">
                  <NavLink to={subNavItem.to} className="sub-nav-link">
                    {subNavItem.title}
                  </NavLink>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
