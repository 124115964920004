import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './category.css';
import { View } from '@aws-amplify/ui-react';
import { fetchcategory } from '../../Common/SharedApis/Category';

type Category = {
    categoryId: number;
    categoryName: string;
};

const CategoryPage = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [page, setPage] = useState(1);
  const [categoriesPerPage, setCategoriesPerPage] = useState(10);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    const fetchCategoryData = async () => {
      if (authToken) {
        setLoading(true);
        try {
          const data = await fetchcategory(1015); // Replace 1015 with actual restaurant ID
          const categoriesWithId = data.map((category: any, index: number) => ({
            ...category,
            id: index + 1,  // Unique ID for the grid
            categoryName: category.categoryName,
          }));
          setCategories(categoriesWithId);
        } catch (error) {
          console.error('Error fetching categories:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCategoryData();
  }, []);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
    setPage(1);
  };

//   const deleteCategory = (categoryID: number) => {
//     const authToken = localStorage.getItem('authToken');

//     axios
//       .delete(`https://restaurantapi.themetasum.com/api/Categories/${categoryID}`, {
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//         },
//       })
//       .then(() => {
//         setCategories((prevCategories) => prevCategories.filter((category) => category.cat_id !== categoryID));
//       })
//       .catch((error) => {
//         console.error('Error deleting category:', error);
//       });
//   };

//   const viewCategoryById = (categoryID: number) => {
//     const category = categories.find((category) => category.cat_id === categoryID);
//     if (category) {
//       confirmAlert({
//         customUI: ({ onClose }) => (
//           <div
//             className="category-popup"
//             style={{
//               backgroundColor: 'white',
//               padding: '20px',
//               borderRadius: '8px',
//               boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)',
//               maxWidth: '400px',
//               minWidth: '400px',
//               margin: '0 auto',
//             }}
//           >
//             <h2 className="popup-header" style={{ color: '#666' }}>Category Details</h2>
//             <p className="popup-category">Category Id: {category.cat_id}</p>
//             <p className="popup-category">Name: {category.cat_name}</p>
//             <p className="popup-category">Restaurant Id: {category.restaurant_id}</p>
//             <button className="close-buttons" onClick={onClose}>
//               Close
//             </button>
//           </div>
//         ),
//         closeOnEscape: true,
//         closeOnClickOutside: true,
//       });
//     }
//   };

//   const confirmDeleteCategory = (categoryID: number) => {
//     confirmAlert({
//       title: 'Confirm to delete',
//       message: 'Are you sure you want to delete this category?',
//       buttons: [
//         {
//           label: 'Yes',
//           onClick: () => deleteCategory(categoryID),
//           style: {
//             backgroundColor: '#ff4d4f',
//           },
//         },
//         {
//           label: 'No',
//           style: {
//             backgroundColor: '#1890ff',
//           },
//         },
//       ],
//       closeOnEscape: true,
//       closeOnClickOutside: true,
//     });
//   };

  const filteredCategories = categories
    .filter((category) => category.categoryName?.toLowerCase().includes(filterValue.toLowerCase()))
    .slice((page - 1) * categoriesPerPage, page * categoriesPerPage);

  const totalPages = Math.ceil(categories.length / categoriesPerPage);
  const pageCount = Math.min(totalPages, 100); // Limit to a maximum of 100 pages

  const columns: GridColDef[] = [
    { field: 'categoryId', headerName: 'Category_ID', flex: 0.5 },
    { field: 'categoryName', headerName: 'Category Name', flex: 1 },
    // { field: 'restaurantId', headerName: 'Restaurant ID', flex: 0.5 },
    // {
    //   field: 'view',
    //   headerName: 'View',
    //   flex: 0.75,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <button onClick={() => viewCategoryById(params.row.categoryId)} className="view-button">
    //       View
    //     </button>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: 'Action',
    //   flex: 0.75,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <button onClick={() => confirmDeleteCategory(params.row.cat_id)} className="delete-button">
    //       Delete
    //     </button>
    //   ),
    // },
  ];

  const handlePageChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleCategoriesPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoriesPerPage(Number(event.target.value));
    setPage(1);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Categories List</h2>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="pagination-controls">
            <span className="pagination-label">Show</span>
            <select
              value={categoriesPerPage}
              onChange={handleCategoriesPerPageChange}
              className="pagination-select"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span className="pagination-label">Pages</span>
          </div>
        </div>

        <div className="search-container">
          <input
            type="text"
            placeholder="Search categories by name..."
            value={filterValue}
            onChange={handleFilterChange}
            className="search-input enhanced-search"
          />
          <span className="search-icon">&#128269;</span>
          {filterValue && (
            <button className="clear-button" onClick={() => setFilterValue('')}>
              &#10006;
            </button>
          )}
        </div>
      </div>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth="100%"
        padding="1rem"
        minHeight="80vh"
      >
        <h2 style={{ margin: '20px 0' }}>Categories</h2>
        <div className="data-grid-container">
          <div className="data-grid">
            <DataGrid rows={filteredCategories} columns={columns} hideFooter />
          </div>
          <div className="pagination">
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              siblingCount={1}
              boundaryCount={1}
            />
          </div>
        </div>
      </View>
    </div>
  );
};

export default CategoryPage;


