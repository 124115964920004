import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon from MUI
import { GetItemWithChoicesAndToppingsById } from '../../Common/SharedApis/Item';

interface Topping {
  toppingId: number;
  toppingName: string;
  toppingPrice: number;
  preSelected: boolean | null;
}

interface ChoicesAddsOnsGroup {
  choicesAddsOnsGroupId: number;
  groupName: string;
  isOptional: boolean;
  allowAddingMultipleTimes: boolean;
  forceMin: number;
  forceMax: number;
  active: boolean;
  toppings: Topping[];
}

interface Variant {
  variantName: string;
  variantPrice: number;
  choicesAddsOnsGroupList: ChoicesAddsOnsGroup[];
}

interface ItemData {
  item_id: number;
  item_barCode: string | null;
  item_name: string;
  item_price: number;
  item_description: string;
  isAvaliableOnline: boolean;
  cat_id: number;
  restaurant_id: number;
  imageFile: string | null;
  isVarient: boolean;
  isDeal: boolean;
  hasToppings: boolean;
  variants: Variant[];
  itemTopings: null;
  choicesAddsOnsGroupList: ChoicesAddsOnsGroup[];
}

const ItemDetail = ({ itemId, open, onClose }) => {
  const [itemData, setItemData] = useState<ItemData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const data = await GetItemWithChoicesAndToppingsById(itemId);
        setItemData(data);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
        setLoading(false);
      }
    };

    fetchItemData();
  }, [itemId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {/* Custom DialogTitle with Close Button */}
      <DialogTitle>
        Item Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">Error: {error}</Typography>
        ) : !itemData ? (
          <Typography>No data found.</Typography>
        ) : (
          <>
            {/* Main Item Info */}
            <Typography variant="h5">{itemData.item_name}</Typography>
            <Typography>Description: {itemData.item_description}</Typography>
            <Typography>Price: ${itemData.item_price}</Typography>
            <Typography>Available Online: {itemData.isAvaliableOnline ? 'Yes' : 'No'}</Typography>

            {/* Variants */}
            {itemData.isVarient && (
              <div className="variants">
                <Typography variant="h6">Variants:</Typography>
                <ul>
                  {itemData.variants.map((variant, index) => (
                    <li key={index}>
                      {variant.variantName} - ${variant.variantPrice}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Add-ons Group */}
            {itemData.choicesAddsOnsGroupList && itemData.choicesAddsOnsGroupList.length > 0 && (
              <div className="addons">
                <Typography variant="h6">Available Add-ons:</Typography>
                {itemData.choicesAddsOnsGroupList.map((group, index) => (
                  <div key={index} className="addon-group">
                    <Typography variant="subtitle1">
                      {group.groupName} (Optional: {group.isOptional ? 'Yes' : 'No'})
                    </Typography>
                    <ul>
                      {group.toppings.map((topping) => (
                        <li key={topping.toppingId}>
                          {topping.toppingName} - ${topping.toppingPrice.toFixed(2)}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemDetail;
