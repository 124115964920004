import React, { useState } from "react";
import { Button, TextField, Alert } from "@aws-amplify/ui-react";
import { Link, useNavigate } from 'react-router-dom';
import "./LoginPage.css";
import { login } from '../../Common/SharedApis/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  // Loading state
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    Password: "",
  });
  const [errorMessage, setErrorMessage] = useState(""); // Use errorMessage for clarity
  const [rememberMe, setRememberMe] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

 const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true); // Set loading to true when submitting
    setErrorMessage(""); // Clear previous error message

    const dataToSend = {
        email: values.email,
        password: values.Password,
    };

    try {
        const responseData = await login(dataToSend);

        // Assuming the responseData contains a userId
        const userId = responseData.userId; // Change this according to your API response structure

        if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
        } else {
            localStorage.setItem('rememberMe', 'false');
        }
        
        // Save the user ID in local storage
        localStorage.setItem('userId', userId);

        navigate('/Myallresturent');
    } catch (error) {
        if (error instanceof Error) {
            if (error.message.includes("")) {
                setErrorMessage("Invalid email or password. Please try again.");
            } else {
                setErrorMessage(error.message);
            }
        } else {
            setErrorMessage("An unknown error occurred. Please try again.");
        }
    } finally {
        setIsLoading(false); // Set loading to false after the request completes
    }
};


  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const GoToNewRestaurant = ()=>{
    navigate("/CreateResturant");
  }
  return (
    <div className="login-page">
      {/* Left Section */}
      <div className="left-section">
        <h1>SumBite</h1>
        <p>Stay In Control</p>
        <p>Track orders in real-time from any device, manage tables, and streamline your restaurant operations effortlessly.</p>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      {/* Right Section */}
      <div className="right-section">
        <form className="login-form" onSubmit={handleSubmit}>
           {/* Display error message as an Alert */}
           {errorMessage && (
            <Alert variation="error" isDismissible={true}>
              {errorMessage}
            </Alert>
          )}
          <h2>Welcome to SumBite</h2>
          <TextField
            value={values.email}
            onChange={handleInputChange}
            name="email"
            label="Email"
            type="email"
            width="100%"
            required
            className="text-field"
          />
          <TextField
            value={values.Password}
            onChange={handleInputChange}
            name="Password"
            label="Password"
            type="password"
            width="100%"
            required
            className="text-field"
          />
          <div className="remember-me">
            <input type="checkbox" id="remember" />
            <label htmlFor="remember">Remember me</label>
          </div>

          {/* Button with loading spinner */}
          <Button className="button-style" type="submit" isLoading={isLoading}>
            Sign In
          </Button>

          <div className="links-container">
  <Link to="/forgot-password">Forgot Password?</Link>
  



 

</div>


<Button onClick={GoToNewRestaurant} className="button-style" type="submit" isLoading={isLoading}>
            Free Register your restaurant 
          </Button>


         
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
