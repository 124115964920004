import apiBaseUrl from '../ApiBaseUrl';

const authToken = localStorage.getItem('authToken')?.toString();

export const fetchOrders = async (
  pageNumber: number, pageSize: number, _searchQuery: string,
) => {
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Orders/getNewOrdersList?restaurant_id=1&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    const contentType = response.headers.get('Content-Type');
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      console.log('Fetched data:', data);

      return {
        orders: Array.isArray(data.ordersVM) ? data.ordersVM : [],
        totalPages: data.totalPages || 0,
      };
    } else {
      const responseBody = await response.text();
      console.error('Unexpected response format:', responseBody);
      throw new Error("Unexpected response format: Expected JSON");
    }
  } 
  catch (error) {
    console.error('Error fetching orders:', error);
    return { orders: [], totalPages: 0 }; // Return empty data on error
  }
};

// Get order dashboard tiles

export const fetchOrderDashboard = async () => {


  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Orders/getOrdersDashboard?restaurant_id=1`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

   
    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    const contentType = response.headers.get('Content-Type');
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      console.log('Fetched dashboard data:', data);
      return data;

      
    } else {
      const responseBody = await response.text();
      console.error('Unexpected response format:', responseBody);
      throw new Error("Unexpected response format: Expected JSON");
    }
  } catch (error) {
    
    console.error('Error fetching dashboard data:', error);
    return null; 
  }
};

// Get order details
export const getOrderDetails = async (orderNumber: number, riderId: number) => {
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Orders/getOrderDetails?OrderNumber=${orderNumber}&riderId=${riderId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    const contentType = response.headers.get('Content-Type');
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      console.log('Fetched order details:', data);

      return data;  // Returning the fetched order details
    } else {
      const responseBody = await response.text();
      console.error('Unexpected response format:', responseBody);
      throw new Error("Unexpected response format: Expected JSON");
    }
  } catch (error) {
    console.error('Error fetching order details:', error);
    return null; // Return null on error
  }
};

// Get order tracking
export const getOrderTracking = async (orderNumber: number) => {
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Orders/TrackOrder?order_id=${orderNumber}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    const contentType = response.headers.get('Content-Type');
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      console.log('Fetched order details:', data);

      return data;  // Returning the fetched order details
    } else {
      const responseBody = await response.text();
      console.error('Unexpected response format:', responseBody);
      throw new Error("Unexpected response format: Expected JSON");
    }
  } catch (error) {
    console.error('Error fetching order details:', error);
    return null; // Return null on error
  }
};