import React, { useState, useEffect } from "react";
import "./Header.css";
import { Navbar, Form, FormControl, Image, InputGroup, Dropdown, Badge, ListGroup } from 'react-bootstrap';
import { FaBell, FaCommentDots, FaSearch } from 'react-icons/fa';
import { logout } from '../../Common/SharedApis/Auth'; // Import the logout function
import SettingsModal from '../../pages/Settings/SettingsModal'; // Import the Settings modal
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Header = () => {

  const navigate = useNavigate(); // Initialize the navigate function

  const [notifications, setNotifications] = useState([
    { id: 1, message: 'New order received', time: '2 mins ago' },
    { id: 2, message: 'Order #12345 has been shipped', time: '1 hour ago' },
    { id: 3, message: 'New comment on your post', time: '3 hours ago' },
  ]);
  const [showSettings, setShowSettings] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let navbarClasses = "top-nav fixed-top";
  if (scrolled) {
    navbarClasses += " navbar-scrolled";
  }

  const handleShowSettings = () => setShowSettings(true);
  const handleCloseSettings = () => setShowSettings(false);

  // Add this function to handle navigating to the chat page
  const handleViewAllMessages = () => {
    navigate('/chat'); // Adjust the path based on your routing structure
  };

  return (
    <>
      <Navbar expand="lg" className={`${navbarClasses} `}>
        {/* Search bar with icon inside */}
        <Form className="w-50">
          <InputGroup>
            <FormControl
              type="text"
              placeholder="Search here"
              className="mr-sm-2 search-bar"
            />
            <InputGroup.Text className="nav-search-icon">
              <FaSearch />
            </InputGroup.Text>
          </InputGroup>
        </Form>

        <div className="d-flex align-items-center">
          {/* Icons with Badges */}
          <div className="d-flex align-items-center mr-4">
            <div className="position-relative mr-4">
              {/* Icon and Badge */}
              <Dropdown>
                <Dropdown.Toggle as="div" className="icon-container">
                  <FaBell size={20} />
                  <Badge pill className="bg-primary badge-notification">
                    {notifications.length}
                  </Badge>
                </Dropdown.Toggle>

                {/* Notifications Dropdown */}
                <Dropdown.Menu className="dropdown-menu-right notification-dropdown">
                  <Dropdown.Header>Notifications</Dropdown.Header>
                  <ListGroup variant="flush">
                    {notifications.length ? (
                      notifications.map((notif) => (
                        <ListGroup.Item key={notif.id}>
                          <div className="d-flex justify-content-between">
                            <div>{notif.message}</div>
                            <small className="text-muted">{notif.time}</small>
                          </div>
                        </ListGroup.Item>
                      ))
                    ) : (
                      <Dropdown.Item>No notifications</Dropdown.Item>
                    )}
                  </ListGroup>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/all-notifications">View all</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="position-relative mr-4">
              {/* Icon and Badge */}
              <Dropdown>
                <Dropdown.Toggle as="div" className="icon-container">
                  <FaCommentDots size={20} />
                  <Badge pill className="bg-primary badge-notification">
                    {notifications.length}
                  </Badge>
                </Dropdown.Toggle>

                {/* Messages Dropdown */}
                <Dropdown.Menu className="dropdown-menu-right notification-dropdown">
                  <Dropdown.Header>Messages</Dropdown.Header>
                  <ListGroup variant="flush">
                    {notifications.length ? (
                      notifications.map((notif) => (
                        <ListGroup.Item key={notif.id}>
                          <div className="d-flex justify-content-between">
                            <div>{notif.message}</div>
                            <small className="text-muted">{notif.time}</small>
                          </div>
                        </ListGroup.Item>
                      ))
                    ) : (
                      <Dropdown.Item>No Messages</Dropdown.Item>
                    )}
                  </ListGroup>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleViewAllMessages}>View all</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {/* User info */}
          <div className="profile-container d-flex align-items-center">
            {/* Profile Dropdown */}
            <Dropdown>
              <Dropdown.Toggle as="div" className="d-flex align-items-center">
                <span className="mr-2">
                  Hello, <strong>Samantha</strong>
                </span>
                <Image
                  src="https://via.placeholder.com/40"
                  roundedCircle
                  className="profile-img"
                />
              </Dropdown.Toggle>

              {/* Dropdown Menu */}
              <Dropdown.Menu className="dropdown-menu-right profile-dropdown">
                <Dropdown.Item href="#/profile">View Profile</Dropdown.Item>
                <Dropdown.Item onClick={handleShowSettings}>Settings</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Navbar>

      {/* Settings Modal */}
      <SettingsModal show={showSettings} handleClose={handleCloseSettings} />
    </>
  );
};

export default Header;
