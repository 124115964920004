import React, { useState, useEffect } from "react";
import { View, Heading, ScrollView } from "@aws-amplify/ui-react";
import { fetchOrders } from "../../Common/SharedApis/Order";
import Orderlist from "./orderlist";

// Define the Order type
interface Order {
  id: number;
  order_id: number;
  order_datetime: string;
  restaurant_id: number | null;
  user_id: number | null;
  order_status_description: string;
  fromResturant: string;
  deliveryAddress: string | null;
  orderTypeName: string;
  price:Number
}

const Tables: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]); // State to store filtered orders
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1); // State to store totalPages
  const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page
  const [filterValue, setFilterValue] = useState(""); // State for filter value

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when search query changes
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterValue(event.target.value);
  };

  useEffect(() => {
    const fetchDataFromAllPages = async () => {
      const allData: Order[] = [];
      let idCounter = 1; // Counter for generating unique IDs

      try {
        let page = 1;
        let totalFetchedPages = 1;

        while (page <= totalFetchedPages) {
          // Use the generic fetchOrders function
          const data = await fetchOrders(page, itemsPerPage, searchQuery);

          const ordersWithIds: Order[] = data.orders.map((order: Order) => ({
            ...order,
            id: idCounter++, // Increment the ID counter for uniqueness
          }));

          allData.push(...ordersWithIds);

          if (page === 1) {
            totalFetchedPages = data.totalPages || 1; // Fetch totalPages from the API response
            setTotalPages(totalFetchedPages); // Update totalPages state
          }

          page++;
        }

        setOrders(allData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setIsLoading(false);
      }
    };

    fetchDataFromAllPages();
  }, [currentPage, searchQuery, itemsPerPage]); // Re-fetch when searchQuery, currentPage, or itemsPerPage changes

  useEffect(() => {
    // Filter orders based on the search query
    const filtered = orders.filter((order) =>
      order.order_id.toString().includes(searchQuery)
    );
    setFilteredOrders(filtered);
  }, [searchQuery, orders]); // Filter whenever searchQuery or orders change

  return (
    <View
      borderRadius="6px"
      maxWidth="100%"
      minHeight="80vh"
    >
      <h2>User Orders</h2>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="pagination-controls">
            <span className="pagination-label">Show</span>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="pagination-select">
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span className="pagination-label">per page</span>
          </div>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search items by OrderID..."
            value={searchQuery}
            onChange={handleSearchInputChange}
            className="search-input enhanced-search"
          />
          <span className="search-icon">&#128269;</span>
          {searchQuery && (
            <button className="clear-buttons" onClick={() => setSearchQuery('')}>
              &#10006;
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <ScrollView width="100%">
            <Orderlist
              orders={filteredOrders}
              isLoading={false}
              pageSize={itemsPerPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages} // Use state value for totalPages
            />
          </ScrollView>
        </div>
      )}
    </View>
  );
};

export default Tables;
