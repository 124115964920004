import apiBaseUrl from '../ApiBaseUrl';

const authToken = localStorage.getItem('authToken')?.toString();

export const fetchCustomers = async (
  pageNumber: number, pageSize: number, _searchQuery: string,
) => {
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Customers/GetCustomersWithOrders?restaurant_id=1&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    const contentType = response.headers.get('Content-Type');
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      console.log('Fetched data:', data);

      return {
        customers: Array.isArray(data.customers) ? data.customers : [],
        totalPages: data.totalPages || 0,
      };
    } else {
      const responseBody = await response.text();
      console.error('Unexpected response format:', responseBody);
      throw new Error("Unexpected response format: Expected JSON");
    }
  } 
  catch (error) {
    console.error('Error fetching orders:', error);
    return { orders: [], totalPages: 0 }; // Return empty data on error
  }
};



