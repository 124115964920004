import { BoundaryModel } from '../../Models/BoundaryModel';
import apiBaseUrl from '../ApiBaseUrl';

const authToken = localStorage.getItem('authToken')?.toString();

export const fetchcategory = async (
 restaurantId: number,
  ) => {
    if (!authToken) {
      throw new Error("No authentication token found. Please log in.");
    }
  
    const apiUrl = `${apiBaseUrl.apiBaseUrl}/Category/GetCategoryByRestaurants?RestaurantId=${restaurantId}`;
    console.log('Request URL:', apiUrl);
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
  //comment
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
  
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);
  
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Fetched data:', data);
  
        return Array.isArray(data) ? data : [];
      } else {
        const responseBody = await response.text();
        console.error('Unexpected response format:', responseBody);
        throw new Error("Unexpected response format: Expected JSON");
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      return [];
    }
  };
  
  export const saveBoundary = async (boundaryModel: BoundaryModel) => {
    console.log('boundaryModel',boundaryModel);
    debugger;
    if (!authToken) {
      throw new Error("No authentication token found. Please log in.");
    }
  
    const apiUrl = `${apiBaseUrl.apiBaseUrl}/DeliveryZones/Add`;
  
    console.log('Request URL:', apiUrl);
    console.log('Request Body:', boundaryModel);
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(boundaryModel),
      });
  
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);
  
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Saved boundary data:', data);
        return data;
      } else {
        const responseBody = await response.text();
        console.error('Unexpected response format:', responseBody);
        throw new Error("Unexpected response format: Expected JSON");
      }
    } catch (error) {
      console.error('Error saving boundary:', error);
      throw error;
    }
  };

  // Function to fetch delivery zones by restaurant ID
export const fetchDeliveryZonesByRestaurant = async (restaurantId: number) => {
    if (!authToken) {
      throw new Error("No authentication token found. Please log in.");
    }
  
    // API endpoint URL
    const apiUrl = `${apiBaseUrl.apiBaseUrl}/DeliveryZones/GetByRestaurant/${restaurantId}`;
    console.log('Request URL:', apiUrl);
  
    try {
      // Making the GET request to the API
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Log response status and headers
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
  
      // Check if the response is in JSON format
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);
  
      if (contentType && contentType.includes('application/json')) {
        // Parse the response JSON data
        const data = await response.json();
        console.log('Fetched delivery zones data:', data);
  
        // Return the parsed data
        return Array.isArray(data) ? data : [];
      } else {
        const responseBody = await response.text();
        console.error('Unexpected response format:', responseBody);
        throw new Error("Unexpected response format: Expected JSON");
      }
    } catch (error) {
      console.error('Error fetching delivery zones:', error);
      return [];
    }
  };