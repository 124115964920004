import React from "react";
import { View } from "@aws-amplify/ui-react";
import AddCategory from "./AddCategory";
const Category = () => {
  return (
    <>
      <div>
        <h2>Add Category</h2>
      </div>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        // minHeight="100vh"
        maxHeight="100%"
      >
        {/* <Heading color="#333"> Basic Form </Heading> */}
        <br></br>
        <AddCategory />
      </View>
    </>
  );
};

export default Category;
