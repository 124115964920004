import React from "react";
import { View } from "@aws-amplify/ui-react";
import Menuepage from "./Menuepage";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
// import { lightBlue } from "@mui/material/colors";

const Menue = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  return (
    <>
      {/* Header section */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h2>Add New Menu Item</h2>    
      </div>

      {/* Main content section */}
      <View
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        maxHeight="100%"
      >
        <br />
        <Menuepage />
      </View>
    </>
  );
};

export default Menue;
