import axios from 'axios';
import apiBaseUrl from '../ApiBaseUrl';

const authToken = localStorage.getItem('authToken')?.toString();

export const fetchItems = async (restaurantId: number) => {
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemsByRestaurant?RestaurantId=${restaurantId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } else {
      throw new Error("Unexpected response format: Expected JSON");
    }
  } catch (error) {
    console.error('Error fetching items:', error);
    return []; // Return empty data on error
  }
};

export const fetchItemById = async (itemId: string) => {
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemById?itemId=${itemId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching item details:', error);
    throw error; // Let the calling function handle the error
  }
};

export const GetItemWithChoicesAndToppingsById = async (itemId: number) => {
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemWithChoicesAndToppingsById?itemId=${itemId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching item details:', error);
    throw error; // Let the calling function handle the error
  }
};

export const fetchItemByCategoryId = async (RestaurantId: string, CategoryId: string) => {
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemsByRestaurantIdAndCategory?RestaurantId=${RestaurantId}&CategoryId=${CategoryId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching item details:', error);
    throw error; // Let the calling function handle the error
  }
};

export const updateItem = async (formData: FormData) => {
  const token = localStorage.getItem('authToken'); // Adjust token retrieval as needed
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'multipart/form-data', // Ensure the Content-Type is set to multipart/form-data
  };

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/UpdateItem`;

  try {
    const response = await axios.post(apiUrl, formData, { headers });
    return response.data;
  } catch (error) {
    console.error("Error updating item:", error);
    throw error;
  }
};

export const addItem = async (formData: FormData) => {
  const token = localStorage.getItem('authToken'); // Adjust token retrieval as needed
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'multipart/form-data', // Ensure the Content-Type is set to multipart/form-data
  };

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/AddRestaurantItems`; // Use the provided API URL

  try {
    const response = await axios.post(apiUrl, formData, { headers });
    return response.data;
  } catch (error) {
    console.error("Error adding item:", error);
    throw error;
  }
}



export const GetItemsWithCategoryByRestaurantId = async (restaurantId: number) => {
  // Check if authentication token exists
  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  // Construct the API URL using the provided restaurant ID
  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemsWithCategoryByRestaurantId?RestaurantId=${restaurantId}`;

  try {
    // Make the API request using fetch
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Assuming you need an auth token
        'Content-Type': 'application/json',
      },
    });

    // Handle if the response is not successful
    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    // Parse the response data
    const data = await response.json();
    return data; // Return the data to the calling function

  } catch (error) {
    // Log the error and rethrow it for handling in the calling function
    console.error('Error fetching items by restaurant ID:', error);
    throw error;
  }
};






