import React, { useEffect, useState } from "react";
import { Button, TextField } from "@aws-amplify/ui-react";
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { getCountryList } from "../../Common/SharedApis/Country"; // Your generic API
import "./CreateRestaurant.css";

// Your Mapbox Access Token
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoidGhlbWV0YXN1bSIsImEiOiJjbTFiczFtMmQwZHdnMmxzNmRsZ292d3hiIn0.XvXHgjczGqyH7DPS8aeN_A';

const CreateRestaurant: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    restaurantName: "",
    address: "",
    countryId: "",
  });
  const [countryList, setCountryList] = useState<any[]>([]);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const loadCountries = async () => {
      const countries = await getCountryList(0);
      setCountryList(countries.map(country => ({
        label: `${country.name} (${country.code})`,
        value: country.name,
        flagUrl: country.flagUrl
      })));
    };

    loadCountries();
  }, []);

  const handleCountryChange = (selectedOption) => {
    setValues({ ...values, countryId: selectedOption.value });
  };

  const getCoordinatesForCountry = async (countryName: string) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(countryName)}.json?access_token=${MAPBOX_ACCESS_TOKEN}&limit=1`
      );
      const data = await response.json();
      if (data && data.features && data.features.length > 0) {
        const [longitude, latitude] = data.features[0].center;
        return { longitude, latitude };
      } else {
        throw new Error("Coordinates not found for the selected country.");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const dataToSend = {
      RestaurantName: values.restaurantName,
      Address: values.address,
      CountryId: values.countryId,
    };

    try {
      console.log('Data Submitted:', dataToSend);
      const countryCoords = await getCoordinatesForCountry(values.countryId);

      if (countryCoords) {
        navigate('/location', {
          state: {
            coords: [countryCoords.longitude, countryCoords.latitude],
            restaurantName: values.restaurantName, // Pass restaurant name here
          },
        });
      } else {
        setResponseMessage("Failed to retrieve coordinates for the selected country.");
      }
    } catch (error) {
      setResponseMessage(error instanceof Error ? error.message : "Unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="sign-up-page">
      <div className="left-section">
        <h1>SumBite</h1>
        <p>Join Us and Stay In Control</p>
        <p>
          Track orders, manage tables, and optimize restaurant operations with
          SumBite.
        </p>
        <div className="social-icons">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      <div className="right-section">
        <form className="sign-up-form" onSubmit={handleSubmit}>
          <h2>Add Your Restaurant</h2>

          <div className="select-wrapper">
            <label htmlFor="country-select" className="select-label">
              Select Country
            </label>
            <Select
              id="country-select"
              options={countryList}
              onChange={handleCountryChange}
              className="select-field"
              placeholder="Select a country..."
              isSearchable
            />
          </div>

          <TextField
            value={values.restaurantName}
            onChange={(e) =>
              setValues({ ...values, restaurantName: e.target.value })
            }
            name="restaurantName"
            label="Restaurant Name"
            type="text"
            width="100%"
            required
            className="text-field"
          />

          <Button type="submit" className="button-style" isLoading={isLoading}>
            Next
          </Button>

          {responseMessage && (
            <div className="response-message">{responseMessage}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateRestaurant;
