import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import "./RegisteredRestaurantsScreen.css";

const WAITINGSCREEN: React.FC = () => {
  const navigate = useNavigate();

  // This effect will run once when the component is mounted
  useEffect(() => {
    // Set a 3-second timeout before redirecting
    const timer = setTimeout(() => {
      navigate("/Allresturents");
    }, 3000); // 3000 milliseconds = 3 seconds

    // Cleanup the timeout if the component unmounts before the time is up
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="sign-up-page">
      <div className="left-section">
        <h1>SumBite</h1>
        <p>Join Us and Stay In Control</p>
        <p>Track orders, manage tables, and optimize restaurant operations with SumBite.</p>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      <div className="right-section">
        <div className="wait-message">
          <h2>Please wait...</h2>
          <p>Your restaurant will be added in a moment.</p>
        </div>
      </div>
    </div>
  );
};

export default WAITINGSCREEN;
