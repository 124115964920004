import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { ThemeProvider } from "@aws-amplify/ui-react";
import theme from "./theme";

import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import Tables from "./pages/tables";
import UsersTable from "./pages/tables/UsersTablePage";
import Forms from "./pages/forms";
import EditForm from "./pages/forms/EditForm";
import Menue from "./pages/Menue/Menue";
import Orderlist from "./pages/Orderlist/OrderlistPage";
import AddCategory from "./pages/AddCategory/Category";
import Item from "./pages/Items/ItemPage";
import StatusBar from "./context/StatusBar";
import { SignalRProvider } from "./context/SignalRProvider";
import ErrorBoundary from "./context/ErrorBoundary";
import useOnlineStatus from "./context/useOnlineStatus";
import LoginPage from "./pages/Login/Loginpage";
import SignUpPage from "./pages/SignUp/SignUpPage";
import DeliveryAreaMap from "./pages/DeliveryMap/DeliveryAreaMap";
import CategoryPage from "./pages/CategoryGrid/categorygrid";
import Layout from "../src/components/Layout";
import NewOrderDetails from "./pages/NewOrderDetails/NewOrderDetails";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import CreateResturant from "./pages/NewRestaurant/CreateRestaurantPage";
import Location from "./pages/NewRestaurant/Location";
import MyAllRestaurant from "./pages/RegisteredRestaurantsScreen/RegisteredRestaurantsScreen";
import WAITINGSCREEN from "./pages/RegisteredRestaurantsScreen/RegisteredRestaurantsScreen";
import Allresturents from "./pages/RegisteredRestaurantsScreen/MyAllResturants";
import GoToTopButton from './components/GotoTop/GotoTop'; 
// import CustomerDetails from "./pages/Customer/customerDetails";
import MainPOS from "./pages/POS/MainPOS";
import CustomerListPage from "./pages/Customer/CustomerListPage";
import KitchenDisplay from "./pages/Kitchen/kitchenDisplay";
import MainDashboard from './pages/Reports/mainDashboard'
import { ToastContainer } from "react-toastify";
import MusicPage from "./pages/Notification Sounds/MusicPage";
import ProtectedRoute from "./context/ProtectedRoute";
import { AudioProvider } from './context/AudioContext';
import EnableNotificationButton from "./context/EnableNotificationButton";
import ChatSidebar from "./pages/Chat/Chat";
import CustomerDetails from "./pages/Customer/customerDetails";
import Test from "./pages/Items/test";
import StepForm from "./pages/Branches/Branches";
import OrderTrackings from "./pages/TrackOrder/ordertracking";
import OrderTracking from "./pages/TrackOrder/Trackorder";
import OrderTrackingleaflet from "./pages/TrackOrder/ordertracking";



export default function App() {

  const isOnline = useOnlineStatus();  // Use the custom hook to check online status
  const [showStatusBar, setShowStatusBar] = useState(true);
  const [statusMessage, setStatusMessage] = useState('No internet connection detected. Please check your network settings.');
  const handleCloseStatusBar = () => {
    setShowStatusBar(false);
  };
  

  return (
    <ThemeProvider theme={theme}>
      <div>

      <StatusBar message={statusMessage} show={!isOnline}  onClose={handleCloseStatusBar}/>
      <AudioProvider>
       <SignalRProvider>
       <ErrorBoundary> 
       <ToastContainer />
       <EnableNotificationButton />
       
        <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
        <Route path="/ResetPassword" element={<ResetPasswordPage/>} />
        <Route path="/CreateResturant" element={<CreateResturant/>} />
        <Route path="/location" element={<Location/>} />
        <Route path="/WAITINGSCREEN" element={<WAITINGSCREEN/>} />



        <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Layout />}>       
            <Route path="forms" element={<Forms />} />
            <Route path="edit-form" element={<EditForm />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="tables" element={<Tables />} />
            <Route path="users-table" element={<UsersTable />} />
            <Route path="ordertrackingmapbox" element={<OrderTracking />} />
            

            <Route path="ordertrackingleaflet" element={<OrderTrackingleaflet />} />

      
            <Route path="profile" element={<Profile />} />
            <Route path="Menue" element={<Menue />} />
            <Route path="Orderlist" element={<Orderlist />} />
            <Route path="AddCategory" element={< AddCategory/>} />
            <Route path="Items" element={<Item/>} />
            <Route path="/music" element={<MusicPage />} /> {/* Add a route for the MusicPage */}
            <Route path="categorygrid" element={<CategoryPage/>} />
            <Route path="Allresturents" element={<Allresturents/>} />
            <Route path="DeliveryAreaMap" element={<DeliveryAreaMap/>} />
            <Route path="NewOrderDetails/:order_id" element={<NewOrderDetails />} />
            <Route path="neworder" element={<NewOrderDetails/>} />
            <Route path="allCustomers" element={<CustomerListPage/>} />
            {/* <Route path="customerDetail" element={<CustomerDetails/>} /> */}
            <Route path="mainPOS" element={<MainPOS/>} />         
            <Route path="kitchenDisplay" element={<KitchenDisplay/>} />
            <Route path="mainDashboard" element={<MainDashboard/>} />
            <Route path="*" element={<NoMatch />} />
            <Route path="Chat" element={<ChatSidebar/>} />
            <Route path='customerDetails' element={<CustomerDetails/>} />
            <Route path="/test" element={<Test />} />   
            <Route path='branches' element={<StepForm/>}/>
            </Route>
       </Route>
        </Routes>   
        </ErrorBoundary>
        </SignalRProvider>
        </AudioProvider>
        <GoToTopButton />
      </div>
    </ThemeProvider>
    
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
