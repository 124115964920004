import React, { useEffect, useState } from "react";
import {
  View,
  Grid,
  Flex,
  Card,
  Placeholder,
  useTheme,
} from "@aws-amplify/ui-react";
import { MdRemoveRedEye, MdWeb, MdPermIdentity } from "react-icons/md";

import MiniStatistics from "./MiniStatistics";
import TrafficSources from "./TrafficSources";
import SalesSummary from "./SalesSummary";
import TrafficSummary from "./TrafficSummary";
import CustomersSummary from "./CustomersSummary";
import DasboardTiles from './DashboardTiles';

import "./Dashboard.css";
import { fetchOrderDashboard } from "../../Common/SharedApis/Order";
import DashboardTiles from "./DashboardTiles";

/// Mock Data
const barChartDataDemo = [
  {
    name: "Web",
    data: [
      11, 8, 9, 10, 3, 11, 11, 11, 12, 13, 2, 12, 5, 8, 22, 6, 8, 6, 4, 1, 8,
      24, 29, 51, 40, 47, 23, 26, 50, 26, 22, 27, 46, 47, 81, 46, 40,
    ],
  },
  {
    name: "Social",
    data: [
      7, 5, 4, 3, 3, 11, 4, 7, 5, 12, 12, 15, 13, 12, 6, 7, 7, 1, 5, 5, 2, 12,
      4, 6, 18, 3, 5, 2, 13, 15, 20, 47, 18, 15, 11, 10, 9,
    ],
  },
  {
    name: "Other",
    data: [
      4, 9, 11, 7, 8, 3, 6, 5, 5, 4, 6, 4, 11, 10, 3, 6, 7, 5, 2, 8, 4, 9, 9, 2,
      6, 7, 5, 1, 8, 3, 12, 3, 4, 9, 7, 11, 10,
    ],
  },
];

const lineChartData = [
  {
    name: "Mobile apps",
    data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
  },
  {
    name: "Websites",
    data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
  },
];

const customersData = [
  {
    name: "New Customers",
    data: [50, 60, 140, 190, 180, 230],
  },
];
const Dashboard = () => {
  const [newOrders, setNewOrders] = useState<number | null>(null);
  const [canceledOrders, setCanceledOrders] = useState<number | null>(null);
  const [barChartData, setBarChartData] = useState<any | null>(null);
  const [trafficSourceData, setTrafficSourceData] = useState<any | null>(null);
  const { tokens } = useTheme();

  const [dashboardTilesData, setDashboardTilesData] = useState<IDashboardOrderTiles[]>([]);


  useEffect(()=>{
      const FetchOrdersData= async () =>{
        const result=await fetchOrderDashboard();
        const getDashboardTilesData = () => {
          const tilesData: IDashboardOrderTiles[] = [
            { title: "Total Orders",
              count: result.totalOrders.count,
              formattedPercentageChange: result.totalOrders.formattedPercentageChange,
              arrowDirection: result.totalOrders.arrowDirection.toLowerCase(),
              icon: '../../../Icon_Order.png'
            },
            {
              title: "Total Delivered",
              count: result.deliveredOrders.count,
              formattedPercentageChange: result.deliveredOrders.formattedPercentageChange,
              arrowDirection: result.deliveredOrders.arrowDirection.toLowerCase(),
              icon: '../../../Icon_Delivered.png' // Ensure this path is correct
            },
            {
              title: "Total Canceled",
              count: result.canceledOrders.count,
              formattedPercentageChange: result.canceledOrders.formattedPercentageChange,
              arrowDirection: result.canceledOrders.arrowDirection.toLowerCase(),
              icon: '../../../Icon_Cancelled.png'
            },
            {
              title: "Total Revenue",
              count: `$${result.totalRevenue.count.toFixed(2)}`, // Formatting the revenue
              formattedPercentageChange: result.totalRevenue.formattedPercentageChange,
              arrowDirection: result.totalRevenue.arrowDirection.toLowerCase(),
              icon: '../../../Icon_Revenue.png'
            }

           ];
          setDashboardTilesData(tilesData);
        };
    
        getDashboardTilesData();
  };
  FetchOrdersData();
  },[]);



  useEffect(() => {
   
   

    const doChartData = async () => {
      const result = await getChartData();
      setBarChartData(result);
      setTrafficSourceData([112332, 123221, 432334, 342334, 133432]);
    };

    doChartData();
  }, []);
  
const getChartData = () =>
  new Promise((resolve, reject) => {
    if (!barChartDataDemo) {
      return setTimeout(() => reject(new Error("no data")), 750);
    }

    setTimeout(() => resolve(Object.values(barChartDataDemo)), 750);
  });


  return (
    <>

      <div>
        <h2>Dashboard</h2>
        <span>Hi, Ali Welcome back to SumBite Admin!</span>
      </div>

      <div className="tiles">
      {dashboardTilesData.map((tile, index) => (
        <DashboardTiles
          key={index}
          title={tile.title}
          count={tile.count}
          formattedPercentageChange={tile.formattedPercentageChange}
          arrowDirection={tile.arrowDirection}
          icon={tile.icon}
        />
      ))}
</div>

      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh">
    
      </View>
    </>
  );
};
export default Dashboard;
