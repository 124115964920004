import { Flex, TextField, Text, Button } from "@aws-amplify/ui-react";
import React, { useState } from "react";

const initialValues = {
  CategoryName: "",
  RestaurantID: "",
};

const AddCategory = () => {
  const [values, setValues] = useState(initialValues);
  const authToken = localStorage.getItem("authToken");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      categoryName: values.CategoryName,
      restaurantId: values.RestaurantID, 
    };

    try {
      const response = await fetch(
        "https://restaurantapi.themetasum.com/api/Category/AddCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status === 401) {
        console.error("Unauthorized: Please log in.");
      } else if (!response.ok) {
        throw new Error("Failed to add category");
      } else {
        console.log("Category added successfully");
        // Reset the form fields to their initial values after successful submission
        setValues(initialValues);
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <Flex as="form" direction="column" width="100%" onSubmit={handleSubmit}>
      <Flex direction="row" width="100%">
        <TextField
          value={values.CategoryName}
          onChange={handleInputChange}
          name="CategoryName"
          maxWidth="25%"
          label={
            <Text>
              Category Name
              <Text as="span" fontSize="0.8rem" color="red">
                *
              </Text>
            </Text>
          }
          type="text"
          isRequired={true}
        />
        <TextField
          value={values.RestaurantID}
          onChange={handleInputChange}
          name="RestaurantID"
          maxWidth="25%"
          label={
            <Text>
              Restaurant ID
              <Text as="span" fontSize="0.8rem" color="red">
                *
              </Text>
            </Text>
          }
          type="text"
          isRequired={true}
        />
      </Flex>
      <Button
        type="submit"
        variation="primary"
        width={{ base: "50%", large: "25%" }}
        style={{ marginLeft: "auto" }}
      >
        Submit
      </Button>
    </Flex>
  );
};

export default AddCategory;
