import React, { useEffect, useRef, useState } from "react"; 
import { Button, TextField } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons'; 
import ReactDOMServer from 'react-dom/server';
import "./Location.css";

mapboxgl.accessToken = 'pk.eyJ1IjoidGhlbWV0YXN1bSIsImEiOiJjbTFiczFtMmQwZHdnMmxzNmRsZ292d3hiIn0.XvXHgjczGqyH7DPS8aeN_A';  

const Location: React.FC = () => {
  const navigate = useNavigate();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    restaurantName: location.state?.restaurantName || "",
    address: "",
    latitude: 0,
    longitude: 0,
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([0, 20]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLDivElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      projection: 'globe',
      center: [0, 20],
      zoom: 1,
      attributionControl: false,
    });
  
   
  
    map.on('load', () => {
      const layerList = document.createElement('div');
      layerList.className = 'map-layers';
  
      const buttonSatellite = document.createElement('button');
      buttonSatellite.textContent = 'Satellite';
      buttonSatellite.onclick = () => map.setStyle('mapbox://styles/mapbox/satellite-v9');
  
      const buttonStreet = document.createElement('button');
      buttonStreet.textContent = 'Street';
      buttonStreet.onclick = () => map.setStyle('mapbox://styles/mapbox/streets-v11');
  
      layerList.appendChild(buttonStreet);
      layerList.appendChild(buttonSatellite);
      
      const mapContainer = map.getContainer();
      mapContainer.appendChild(layerList);
  
      const geocoder = new MapboxGeocoder({
        mapboxgl: mapboxgl,
        accessToken: mapboxgl.accessToken,
        marker: false, // Disable default marker
      });
      map.addControl(geocoder);
      map.addControl(new mapboxgl.NavigationControl());
      const pinIconContainer = document.createElement('div');
      pinIconContainer.className = 'mapboxgl-marker-icon';
  
      const pinIcon = (
        <FontAwesomeIcon icon={faMapPin} style={{ fontSize: '30px', color: 'red', cursor: 'pointer' }} />
      );
  
      pinIconContainer.innerHTML = ReactDOMServer.renderToString(pinIcon);
  
      const marker = new mapboxgl.Marker({ element: pinIconContainer })
        .setLngLat(markerPosition)
        .addTo(map);
  
      if (location.state?.coords) {
        const [longitude, latitude] = location.state.coords;
        map.flyTo({ center: [longitude, latitude], zoom: 5 });
        marker.setLngLat([longitude, latitude]);
        setMarkerPosition([longitude, latitude]);
        setValues((prev) => ({ ...prev, latitude, longitude })); // Set latitude and longitude
      }
  
      map.on('move', () => {
        const center = map.getCenter();
        marker.setLngLat(center);
      });
  
      map.on('moveend', () => {
        const markerLngLat = marker.getLngLat();
        setMarkerPosition([markerLngLat.lng, markerLngLat.lat]);
        setValues((prev) => ({
          ...prev,
          latitude: markerLngLat.lat,
          longitude: markerLngLat.lng,
        }));
        console.log(`Longitude: ${markerLngLat.lng}, Latitude: ${markerLngLat.lat}`);
      });
    });
  
    return () => map.remove();
  }, [location.state]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    // Log all data to the console
    console.log("Submitted Data:", values);

    // Handle form submission logic (e.g., saving restaurant data)

    // Navigate to the /signup page with state
    navigate('/signup', { state: values });

    setIsLoading(false);
  };

  return (
    <div className="sign-up-page">
      <div className="right-section">
        <form className="sign-up-form" onSubmit={handleSubmit}>
          <h2>Add Your Restaurant Location</h2>
          <TextField
            value={values.restaurantName}
            onChange={handleInputChange}
            name="restaurantName"
            label="Restaurant Name"
            type="text"
            width="100%"
            required
            className="text-field"
          />
          <TextField
            value={values.address}
            onChange={handleInputChange}
            name="address"
            label="Address"
            type="text"
            width="100%"
            required
            className="text-field"
          />

          <Button type="submit" className="button-style" isLoading={isLoading}>
            Next
          </Button>

          {responseMessage && <div className="response-message">{responseMessage}</div>}
        </form>
      </div>
      <div className="left-sections">
        <div ref={mapContainerRef} className="map-container" />
      </div>
    </div>
  );
};

export default Location;
