import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Accordion } from 'react-bootstrap';
import { GetKitchenDisplayOrdersByStatus , AddTopping } from '../../Common/SharedApis/ChoicesAndAddons'; // Adjust the import path

interface AddOnesgroupsModelProps {
  show: boolean;
  handleClose: () => void;
  restaurantId: number; // Ensure this matches the expected type
  onSelectedGroupsChange: (selectedGroups: number[]) => void;
}

const AddOnesgroupsModel: React.FC<AddOnesgroupsModelProps> = ({ show, handleClose, restaurantId, onSelectedGroupsChange }) => {
  const [choices, setChoices] = useState<any[]>([]); // Adjust the type as necessary
  const [isAddingGroup, setIsAddingGroup] = useState<string | null>(null);
  const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
  const [newChoice, setNewChoice] = useState({ name: '', price: 0, preSelect: false });
  const [error, setError] = useState<string | null>(null);

  const handleGroupSelect = (group) => {
    const isAlreadySelected = selectedGroups.some((selectedGroup) => selectedGroup.choicesAddsOnsGroupId === group.choicesAddsOnsGroupId);
    const updatedSelection = isAlreadySelected
      ? selectedGroups.filter((selectedGroup) => selectedGroup.choicesAddsOnsGroupId !== group.choicesAddsOnsGroupId)
      : [...selectedGroups, group];

    setSelectedGroups(updatedSelection);
    onSelectedGroupsChange(updatedSelection); // Pass selected groups back to parent
  };

  useEffect(() => {
    const fetchChoices = async () => {
      try {
        if (!restaurantId) {
          throw new Error('Restaurant ID is not provided');
        }

        const data = await GetKitchenDisplayOrdersByStatus(restaurantId);
        setChoices(data); // Assuming `data` matches the structure used in your original choices
      } catch (err: any) {
        setError(err.message || 'Error fetching choices and addons');
      }
    };

    // Call the fetchChoices function only if the modal is shown
    if (show) {
      fetchChoices();
    }
  }, [show, restaurantId]); // Depend on `show` to trigger the API call when the modal is opened

  const handleAddChoice = async (groupName: string) => {
    try {
      const toppingData = {
        toppingName: newChoice.name,
        toppingPrice: newChoice.price,
        restaurant_id: restaurantId,
        choicesAddsOnsGroupId: choices.find(group => group.groupName === groupName)?.choicesAddsOnsGroupId, // Get the correct ID
      };

      const response = await AddTopping(toppingData); // Call the API to add topping
      if (response) {
        // Update the local state to reflect the newly added topping
        setChoices(prevChoices => {
          const updatedChoices = prevChoices.map(group => {
            if (group.groupName === groupName) {
              return {
                ...group,
                toppings: [...group.toppings, { ...toppingData, toppingID: response.toppingID }], // Assuming response contains the new topping ID
              };
            }
            return group;
          });
          return updatedChoices;
        });
      }

      // Reset form
      setIsAddingGroup(null);
      setNewChoice({ name: '', price: 0, preSelect: false });
    } catch (err) {
      console.error(err);
      setError('Error adding choice. Please try again.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setNewChoice({
      ...newChoice,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" className="settings-modal-custom">
      <Modal.Header closeButton>
        <Modal.Title>Choices & Addons</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="text-danger">{error}</div>}
        <Accordion>
          {choices.map((group) => (
            <Accordion.Item key={group.choicesAddsOnsGroupId} eventKey={group.groupName}>
              <Accordion.Header>
                {group.groupName}
              </Accordion.Header>
              <Accordion.Body>
                {group.toppings.map((topping) => {
                  const price = Number(topping.toppingPrice); // Ensure toppingPrice is a number
                  return (
                    <div key={topping.toppingID} className="d-flex justify-content-between align-items-center mb-2">
                      <span>{topping.toppingName}</span>
                      <span>${isNaN(price) ? "N/A" : price.toFixed(2)}</span> {/* Handle NaN case */}
                    </div>
                  );
                })}
                {isAddingGroup === group.groupName && (
                  <Form>
                    <Form.Group controlId="formChoiceName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={newChoice.name}
                        onChange={handleInputChange}
                        maxLength={80}
                        placeholder="Enter choice name"
                      />
                    </Form.Group>
                    <Form.Group controlId="formChoicePrice">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={newChoice.price}
                        onChange={handleInputChange}
                        placeholder="Enter price"
                      />
                    </Form.Group>
                    <Form.Group controlId="formPreSelect">
                      <Form.Check
                        type="checkbox"
                        name="preSelect"
                        label="Pre-select"
                        checked={newChoice.preSelect}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                      <Button variant="secondary" onClick={() => setIsAddingGroup(null)} className="mr-2">
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={() => handleAddChoice(group.groupName)}>
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
                {isAddingGroup !== group.groupName && (
                  <Button variant="link" onClick={() => setIsAddingGroup(group.groupName)}>
                    Add choice
                  </Button>
                )}
                <div className="d-flex justify-content-end mt-3">
                <Button
                  variant={selectedGroups.some(selectedGroup => selectedGroup.choicesAddsOnsGroupId === group.choicesAddsOnsGroupId) ? 'success' : 'outline-primary'}
                  onClick={() => handleGroupSelect(group)}
                >
                  {selectedGroups.some(selectedGroup => selectedGroup.choicesAddsOnsGroupId === group.choicesAddsOnsGroupId) ? 'Deselect' : 'Select'}
                </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="custom-button-secondary">
          Close
        </Button>
        <Button variant="primary" className="custom-button">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOnesgroupsModel;
