import React, { useState, useEffect, FormEvent } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { fetchItemById, updateItem, addItem } from "../../Common/SharedApis/Item";
import { Button, Form, Alert, Card, Row, Col, Spinner } from 'react-bootstrap';
import { FaImage, FaEdit, FaPlus } from 'react-icons/fa';
import "./Menue.css";
import AddOnesgroupsModel from "./AddOnesgroups";

const initialValues = {
  Itemcode: "",
  Itemname: "",
  Price: "",
  Description: "",
  ImageFile: "",
};

const Menuepage = () => {
  const [values, setValues] = useState(initialValues);
  const [image, setImage] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [varients, setVarients] = useState<{ variantName: string, variantPrice: string }[]>([]);

  const [ChoicesAddOnsForItem, setChoicesAddOnsForItem] = useState<{ item_id: number, varient_id: number,ChoicesAddsOnsGroupId:number }[]>([]);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const itemId = queryParams.get('itemId');
  const isEditMode = itemId !== null;
  const [ShowAddOnePopup, setShowAddOnePopup] = useState(false);
  const [selectedAddOnsGroups, setSelectedAddOnsGroups] = useState<any[]>([]);

  const handleAddoneOpenPopup = () => setShowAddOnePopup(true);
  const handleAddoneClosePopup = () => setShowAddOnePopup(false);

  const handleSelectedGroupsChange = (groups) => {
    setSelectedAddOnsGroups(groups); // Update selected groups
  };
  const handleRemoveGroup = (groupId: number) => {
    setSelectedAddOnsGroups((prevGroups) =>
      prevGroups.filter((group) => group.choicesAddsOnsGroupId !== groupId)
    );
  };

  
  useEffect(() => {
    if (isEditMode && itemId) {
      const fetchItemDetails = async () => {
        try {
          const data = await fetchItemById(itemId);
          setValues({
            Itemcode: data.itemId,
            Itemname: data.itemName,
            Price: data.itemPrice,
            Description: data.itemDescription || "Default description",
            ImageFile: data.largeImage || "",
          });

          const fetchedSizes = data.sizes && data.sizes.length > 0 
            ? data.sizes.map(size => ({ variantName: size.size, variantPrice: size.price })) 
            : [];

          setVarients(fetchedSizes);
        } catch (error) {
          console.error("Error fetching item details:", error);
          setErrorMessage("Failed to fetch item details. Please try again.");
        }
      };

      fetchItemDetails();
    }
  }, [itemId, isEditMode]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);
    setFieldErrors({});

    const newFieldErrors: { [key: string]: string } = {};
    if (!values.Itemcode) newFieldErrors.Itemcode = "Item code is required.";
    if (!values.Itemname) newFieldErrors.Itemname = "Item name is required.";
    if (!values.Price) newFieldErrors.Price = "Price is required.";
    if (!values.Description) newFieldErrors.Description = "Description is required.";

    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      setIsLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("item_id", values.Itemcode);
      formData.append("item_name", values.Itemname);
      formData.append("item_price", values.Price);
      formData.append("item_description", values.Description);
      formData.append("IsAvaliableOnline", "true");
      formData.append("Cat_id", "1");
      formData.append("restaurant_id", "1");
      formData.append("isVarient", "true");
      formData.append("isDeal", "false");
      formData.append("HasToppings", "false");

      if (image) {
        formData.append("ImageFile", image);
      } else if (!isEditMode) {
        formData.append("ImageFile", "");
      }

      formData.append("Variants", JSON.stringify(varients));

      // // Adding the selected Add-Ons to the form data
      // const addOnGroupsIds = selectedAddOnsGroups.map(group => group.choicesAddsOnsGroupId);
      // formData.append("ItemTopings", JSON.stringify(addOnGroupsIds));


      const itemToppings = selectedAddOnsGroups.map((group) => {
        return {
          item_id: 0,
          varient_id: 0,
          ChoicesAddsOnsGroupId: group.choicesAddsOnsGroupId
        }
      });      
      formData.append("ItemToppings", JSON.stringify(itemToppings));


      if (isEditMode) {
        await updateItem(formData);
        setSuccessMessage("Item updated successfully!");
      } else {
        await addItem(formData);
        setSuccessMessage("Item added successfully!");
      }

      setTimeout(() => {
        navigate("/items");
      }, 2000);
    } catch (error) {
      console.error("Error processing item:", error);
      setErrorMessage("Failed to process item. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "Itemcode" || name === "Price") {
      if (isNaN(Number(value))) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter only numbers.",
        }));
        return;
      }
    }

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setImage(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Function to add a new size/price row
  const addSizePriceRow = () => {
    setVarients([...varients, { variantName: "", variantPrice: "" }]);
  };
const addOnesMethodClick=()=>{
  setShowAddOnePopup(true);
}
  // Function to remove a size/price row
  const removeSizePriceRow = (index: number) => {
    setVarients(varients.filter((_, i) => i !== index));
  };
  const handleButtonClick = () => {
    navigate("/Items"); // Use navigate to go to the "/Items" route
  };

  return (
    <>
      <div className="items-container">
        <Card className="p-4 shadow-lg rounded">
          <Card.Header>
          <button  className="btn btn-primary" onClick={handleButtonClick}> Go to menu list</button>
          </Card.Header>
          <Card.Body>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <Form id="myForm" onSubmit={handleSubmit}>


            <Row className="mb-3">
  <Col md={12}> {/* Change this to md={12} to take full width */}
    <Form.Group controlId="itemImage" className="mb-3">
      <Form.Label>Item Image</Form.Label>
      <div
        {...getRootProps()}
        className={`dropzone p-3 rounded shadow-sm ${isDragActive ? 'active' : ''}`}
      >
        <input {...getInputProps()} />
        {image ? (
          <>
            <p>Image: {image.name}</p>
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="image-preview"
            />
          </>
        ) : (
          <p>
            <FaImage /> Drag 'n' drop an image here, or click to select one
          </p>
        )}
      </div>
    </Form.Group>
  </Col>
</Row>


              <Row className="mb-3">
                <Col md={8}>
                  <Form.Group controlId="itemCode">
                    <Form.Label>Item Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="Itemcode"
                      value={values.Itemcode}
                      onChange={handleInputChange}
                      disabled={isEditMode}
                      placeholder="Enter item code"
                      className="shadow-sm"
                    />
                    {fieldErrors.Itemcode && (
                      <Form.Text className="text-danger">{fieldErrors.Itemcode}</Form.Text>
                    )}
                  </Form.Group>
                </Col>

              </Row>

              <Row className="mb-3">
                <Col md={varients.length === 0 ? 8 : 12}>
                  <Form.Group controlId="Itemname">
                    <Form.Label>Item Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="Itemname"
                      value={values.Itemname}
                      onChange={handleInputChange}
                      placeholder="Enter item name"
                      className="shadow-sm"
                    />
                    {fieldErrors.Itemname && (
                      <Form.Text className="text-danger">{fieldErrors.Itemname}</Form.Text>
                    )}
                  </Form.Group>
                </Col>

                {varients.length === 0 && (
                  <Col md={4}>
                    <Form.Group controlId="Price">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type="text"
                        name="Price"
                        value={values.Price}
                        onChange={handleInputChange}
                        placeholder="Enter item price"
                        className="shadow-sm"
                      />
                      {fieldErrors.Price && (
                        <Form.Text className="text-danger">{fieldErrors.Price}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                )}
              </Row>

              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="Description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="Description"
                      value={values.Description}
                      onChange={handleInputChange}
                      rows={3}
                      placeholder="Enter item description"
                      className="shadow-sm"
                    />
                    {fieldErrors.Description && (
                      <Form.Text className="text-danger">{fieldErrors.Description}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
            
              <Col className="col-sm-9">
                {selectedAddOnsGroups.length > 0 && (
                  <div>
                    <span>Selected Add-ons</span>
                  
                    <div className="mt-3">
                    {selectedAddOnsGroups.length > 0 ? (
                      <>
                        <div className="d-flex flex-wrap gap-2">
                          {selectedAddOnsGroups.map((group) => (
                            <span key={group.choicesAddsOnsGroupId} className="badge badge-pill bg-success px-3 py-2">
                              {group.groupName}
                              <button
                                type="button"
                                className="btn-close ms-2"
                                onClick={() => handleRemoveGroup(group.choicesAddsOnsGroupId)}
                                aria-label="Close"
                              ></button>
                            </span>
                          ))}
                        </div>
                       
                      </>
                    ) : (
                      <p>No Add-ons selected.</p>
                    )}
                  </div>

                  </div>
                )}
              </Col>

              <Col className="col-sm-3 d-flex justify-content-end">
                <Button variant="secondary" onClick={addOnesMethodClick} className="mb-3">
                  Add Ones
                </Button>
              </Col>
              </Row>
              {varients.map((variant, index) => (
                <Row className="mb-3" key={index}>
                  <Col md={5}>
                    <Form.Group controlId={`variantName-${index}`}>
                      <Form.Label>Variant Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={variant.variantName}
                        onChange={(e) => {
                          const updatedVariants = [...varients];
                          updatedVariants[index].variantName = e.target.value;
                          setVarients(updatedVariants);
                        }}
                        placeholder="Enter variant name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group controlId={`variantPrice-${index}`}>
                      <Form.Label>Variant Price</Form.Label>
                      <Form.Control
                        type="text"
                        value={variant.variantPrice}
                        onChange={(e) => {
                          const updatedVariants = [...varients];
                          updatedVariants[index].variantPrice = e.target.value;
                          setVarients(updatedVariants);
                        }}
                        placeholder="Enter variant price"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2} className="d-flex align-items-end">
                    <Button variant="danger" onClick={() => removeSizePriceRow(index)}>
                      Remove
                    </Button>
                  </Col>
                </Row>
              ))}

<Row className="mb-3">
    <Col>
    <Button variant="primary" onClick={addSizePriceRow} className="mb-3">
                Add Variant
              </Button>
    </Col>
  
</Row>
           

          
            </Form>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col className="d-flex justify-content-end">
              <Button variant="success" type="submit" form="myForm" disabled={isLoading}>
                {isLoading ? <Spinner animation="border" size="sm" /> : isEditMode} 
                {isEditMode ? " Update" : " Save"}
              </Button>
              </Col>
            </Row>
      
          </Card.Footer>
        </Card>
      </div>

      <AddOnesgroupsModel show={ShowAddOnePopup} handleClose={handleAddoneClosePopup} restaurantId={1} onSelectedGroupsChange={handleSelectedGroupsChange}></AddOnesgroupsModel>
    </>
  );
};

export default Menuepage;
