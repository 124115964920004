import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import './ItemsWithCategory.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Modal from 'react-modal'; // Using react-modal for the popup
import ItemDetail from './ItemDetail';

interface Category {
  id: number;
  name: string;
  items: number;
}

interface Item {
  itemId: number;
  name: string;
  price: number;
  description: string;
  image: string;
}

interface ItemsWithCategoryProps {
  categories: Category[];
  items: Record<string, Item[]>;
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 2,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ItemsWithCategory: React.FC<ItemsWithCategoryProps> = ({ categories, items }) => {
  const [activeCategory, setActiveCategory] = useState(categories[0]?.id.toString() || 'all'); // Default to first category
  const [searchTerm, setSearchTerm] = useState('');
  const categoryRefs = useRef<(HTMLDivElement | null)[]>([]);
  const sliderRef = useRef<Slider | null>(null);

  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null); // State to track selected item

  const openModal = (itemId: number) => {
    setSelectedItemId(itemId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItemId(null);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const categoryId = entry.target.id.replace('section-', '');
          setActiveCategory(categoryId); // Update active category
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    categoryRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      categoryRefs.current.forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [categories]);

  useEffect(() => {
    if (sliderRef.current) {
      const activeIndex = categories.findIndex(cat => cat.id.toString() === activeCategory);
      if (activeIndex !== -1) {
        const centeredIndex = Math.max(0, activeIndex - Math.floor(settings.slidesToShow / 2));
        sliderRef.current.slickGoTo(centeredIndex);
      }
    }
  }, [activeCategory, categories]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setActiveCategory(categories[0]?.id.toString());
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [categories]);

  const handleCategoryClick = (categoryId: number) => {
    setActiveCategory(categoryId.toString());

    const element = document.getElementById(`section-${categoryId}`);
    if (element) {
      const yOffset = -100;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div className="menu-container">
      <div className="sticky-wrapper">
        <div className='row categories-top-bar'>
          <div className='col-md-4'>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search categories..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className='col-md-8'>
            <ul className="menu_tabs_wrapper">
              <Slider ref={sliderRef} {...settings}>
                {categories
                  .filter(category => category.name.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map((category) => (
                    <div className="menu-category-item" key={category.id}>
                      <li
                        className={activeCategory === category.id.toString() ? 'active' : ''}
                        onClick={() => handleCategoryClick(category.id)}
                      >
                        <h6>
                          <a href="#" onClick={(e) => e.preventDefault()}>{category.name}</a>
                        </h6>
                        <span>{category.items} Items</span>
                      </li>
                    </div>
                  ))}
              </Slider>
            </ul>
          </div>
        </div>
      </div>

      {categories.map((category) => (
        <div key={category.id} id={`section-${category.id}`} ref={el => categoryRefs.current[category.id] = el} className="category-section">
          <h2>{category.name}</h2>
          <div className="item-list">
            {items[category.name]?.map((item, idx) => (
              <ItemCard key={idx} item={item} onClick={() => openModal(item.itemId)} />
            ))}
            {items[category.name]?.length === 0 && <p>No items available in this category.</p>}
          </div>
        </div>
      ))}

      {/* Modal for item details */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Item Details" className="item-modal">
  {selectedItemId && (
    <div className="item-modal-content">
      {/* Pass `itemId`, `open`, and `onClose` props to `ItemDetail` */}
      <ItemDetail itemId={selectedItemId} open={modalIsOpen} onClose={closeModal} />
    </div>
  )}
</Modal>

    </div>
  );
};

const ItemCard = ({ item ,onClick }: { item: Item, onClick: () => void }) => (
  <div className="item-card" onClick={onClick}>
    <img src={item.image} alt={item.name} />
    <div className="item-details">
      <h3>{item.name}</h3>
      <p>from Rs. {item.price}</p>
      <p>{item.description}</p>
    </div>
  </div>
);

export default ItemsWithCategory;
